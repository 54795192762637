import styles from "./SystemFeatures.module.less";

export function SystemFeatures() {
  return (
    <section id="features" className={styles.systemFeatures}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.heading}>Возможности системы</h2>
        <div className={styles.featureBlock}>
          <div className={styles.textBlock}>
            <div className={styles.role}>Для администратора</div>
            <div className={styles.subheading}>Расписание и запись</div>
            <p>
              Простая &nbsp;
              <span className={styles.highlighted}>
                форма онлайн-записи и переноса визитов.
              </span>
              &nbsp; Цветовая индикация пациентов по видам услуг.
            </p>
            <p>
              Лист ожидания клиентов. Напоминания и &nbsp;
              <span className={styles.highlighted}>
                уведомления об изменениях по СМС и email
              </span>
              &nbsp; включены в тариф.
            </p>
          </div>
          <div className={styles.imgContainer}>
            <img
              src="../../img/timetable-img.png"
              className={styles.preview}
              alt="Скрин раздела программы с расписанием"
            />
          </div>
        </div>
        <div className={styles.featureBlock}>
          <div className={styles.textBlock}>
            <div className={styles.role}>Для врача</div>
            <div className={styles.subheading}>Визиты и план лечения</div>
            <p>
              Наглядная &nbsp;
              <span className={styles.highlighted}>
                зубная карта и справочник дефектов
              </span>
              &nbsp; для быстрой фиксации результатов осмотра.
            </p>
            <p>
              Справочник процедур помогает врачу создать &nbsp;
              <span className={styles.highlighted}>
                несколько вариантов плана лечения
              </span>
              &nbsp; и распечатать их для пациента.
            </p>
            <p>
              Система хранит &nbsp;
              <span className={styles.highlighted}>полную историю приемов</span>
              &nbsp; и проведенных процедур.
            </p>
          </div>
          <div className={styles.imgContainer}>
            <img
              src="../../img/toothmap-img.png"
              className={styles.preview}
              alt="Скрин раздела программы с созданием плана лечения"
            />
          </div>
        </div>
        <div className={styles.featureBlock}>
          <div className={styles.textBlock}>
            <div className={styles.role}>Для руководителя</div>
            <div className={styles.subheading}>Отчетность</div>
            <p>
              <span className={styles.highlighted}>Отчет по продажам.</span>{" "}
              &nbsp; Информация о каждой продаже, а также общая сумма прибыли за
              указанный период.
            </p>
            <p>
              <span className={styles.highlighted}>
                Отчет по работе с пациентами.
              </span>
              &nbsp; Общее количество визитов с разбивкой по первичным и
              вторичным приемам.
            </p>
          </div>
          <div className={styles.imgContainer}>
            <img
              src="../../img/appoitment-img.png"
              className={styles.preview}
              alt="Скрин программы со списком визитов пациентов"
            />
          </div>
        </div>
        <div className={styles.featureBlock}>
          <div className={styles.textBlock}>
            <div className={styles.role}>Для всех</div>
            <div className={styles.subheading}>
              Складской учет
              <br />и договоры
            </div>
            <p>
              <span className={styles.highlighted}>
                Складской учет по материалам
              </span>{" "}
              &nbsp; для одного или нескольких складов.
            </p>
            <p>
              Шаблоны для &nbsp;
              <span className={styles.highlighted}>
                быстрого оформления договоров
              </span>
              &nbsp; с пациентами. Документы можно сразу распечатать.
            </p>
          </div>
          <div className={styles.imgContainer}>
            <img
              src="../../img/stock-img.png"
              className={styles.preview}
              alt="Скрин раздела с остатками на складах"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
