import styles from "./CookiesWindow.module.less";
import { useState } from "react";

export function CookiesWindow() {
  const [isShow, setIsShow] = useState<boolean>(true);
  return isShow ? (
    <div className={styles.card}>
      <p className={styles.warningText}>
        Наш сайт использует куки. Продолжая использовать сайт, вы даёте согласие
        на работу с этими файлами.
      </p>
      <button onClick={() => setIsShow(false)} className={styles.acceptBtn}>
        Хорошо
      </button>
    </div>
  ) : null;
}
