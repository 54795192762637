import React from "react";
import "./App.css";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Benefits } from "./components/Benefits/Benefits";
import { UseTerms } from "./components/UseTerms/UseTerms";
import { SystemFeatures } from "./components/SystemFeatures/SystemFeatures";
import { ClientSupport } from "./components/ClientSupport/ClientSupport";
import { Intro } from "./components/Intro/Intro";
import { CookiesWindow } from "./components/CookiesWindow/CookiesWindow";
import { ProgressiveImage } from "./ProgressiveImage";

function App() {
  return (
    <div className="wrapper">
      <Header />
      <div className="background" id="main">
        <Intro />
        <ProgressiveImage
          blurImageUrl={"../img/backgroundBlur.jpg"}
          mainImageUrl={"../img/backgroundNew.jpg"}
          className={"backgroundImg"}
        />
      </div>
      <Benefits />
      <SystemFeatures />
      <UseTerms />
      <ClientSupport />
      <Footer />
      <CookiesWindow />
    </div>
  );
}

export default App;
