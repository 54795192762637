import styles from "./Benefits.module.less";
import cx from "classnames";

export function Benefits() {
  return (
    <section className={styles.benefits}>
      <div>
        <h2 className={styles.heading}>
          Что дает <br className={styles.lineBreak} />
          НТ Стоматология
        </h2>
        <div className={styles.cardsWrapper}>
          <div className={styles.card}>
            <div className={styles.icon1}></div>
            <div className={styles.cardText}>
              Автоматизация лечебного процесса
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.icon2}></div>
            <div className={styles.cardText}>
              Ведение картотеки пациентов в электронном виде
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.icon3}></div>
            <div className={styles.cardText}>
              Складской учет расходных материалов
            </div>
          </div>
        </div>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <div className={cx(styles.listIcon, styles.icon4)}></div>
            <div className={styles.listText}>
              Простая и понятная замена Excel или устаревшей программы
            </div>
          </li>
          <li className={styles.listItem}>
            <div className={cx(styles.listIcon, styles.icon5)}></div>
            <div className={styles.listText}>
              Единый годовой тариф без скрытых доплат
            </div>
          </li>
          <li className={styles.listItem}>
            <div className={cx(styles.listIcon, styles.icon6)}></div>
            <div className={styles.listText}>
              Нет расходов на ИТ-специалиста и покупку сервера
            </div>
          </li>
          <li className={styles.listItem}>
            <div className={cx(styles.listIcon, styles.icon7)}></div>
            <div className={styles.listText}>
              Соблюдение требований <br />
              Роскомнадзора по хранению данных
            </div>
          </li>
          <li className={styles.listItem}>
            <div className={cx(styles.listIcon, styles.icon8)}></div>
            <div className={styles.listText}>
              Старт без длительного обучения <br />и сложной настройки
            </div>
          </li>
          <li className={styles.listItem}>
            <div className={cx(styles.listIcon, styles.icon9)}></div>
            <div className={styles.listText}>
              Хранение данных в защищенных <br />
              дата-центрах
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}
