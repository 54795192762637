import styles from "./Intro.module.less";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import React, { useState } from "react";
import { RequestSentModalWindow } from "../RequestSentModalWindow/RequestSentModalWindow";

export function Intro() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [requestSentIsOpen, setRequestSentIsOpen] = useState<boolean>(false);

  return (
    <div className={styles.intro}>
      <h1 className={styles.heading}>
        Система учета и управления стоматологией
      </h1>
      <div className={styles.annotation}>
        Простое решение для стоматологических клиник и кабинетов
      </div>
      <button onClick={() => setIsOpen(true)} className={styles.applyBtn}>
        Начать бесплатно
      </button>
      {isOpen && (
        <ModalWindow
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSubmit={() => {
            setIsOpen(false);
            setRequestSentIsOpen(true);
          }}
        />
      )}
      {requestSentIsOpen && (
        <RequestSentModalWindow
          isOpen={requestSentIsOpen}
          onClose={() => setRequestSentIsOpen(false)}
        />
      )}
    </div>
  );
}
