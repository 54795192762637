import ReactDOM from "react-dom";
import styles from "./PrivacyModal.module.less";
import React, { useEffect } from "react";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function PrivacyModal({ isOpen, onClose }: ModalProps) {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onClose]);

  if (!isOpen) return null;
  const modalRoot = document.getElementById("portal") as HTMLElement;
  const policyData = [
    {
      id: 1,
      title: "Общие положения",
      list: [
        '1.1 ООО"НТ Разработки" (далее по тексту – Оператор) ставит соблюдение прав и свобод граждан одним из важнейших условий осуществления своей деятельности.',
        "1.2 Политика Оператора в отношении обработки персональных данных (далее по тексту — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта nt-dent.ru. Персональные данные обрабатывается в соответствии с ФЗ «О персональных данных» № 152-ФЗ.",
      ],
    },
    {
      id: 2,
      title: "Основные понятия, используемые в Политике",
      list: [
        "2.1 Веб-сайт - совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу nt-dent.ru;",
        "2.2 Пользователь – любой посетитель веб-сайта nt-dent.ru;",
        "2.3 Персональные данные – любая информация, относящаяся к Пользователю веб-сайта nt-dent.ru;",
        "2.4 Обработка персональных данных - любое действие с персональными данными, совершаемые с использованием ЭВМ, равно как и без их использования;",
        "2.5 Обезличивание персональных данных – действия, результатом которых является невозможность без использования дополнительной информации определить принадлежность персональных данных конкретному Пользователю или лицу;",
        "2.6 Распространение персональных данных – любые действия, результатом которых является раскрытие персональных данных неопределенному кругу лиц;",
        "2.7 Предоставление персональных данных – любые действия, результатом которых является раскрытие персональных данных определенному кругу лиц;",
        "2.8 Уничтожение персональных данных – любые действия, результатом которых является безвозвратное уничтожение персональных на ЭВМ или любых других носителях.",
      ],
    },
    {
      id: 3,
      title:
        "Оператор может обрабатывать следующие персональные данные Пользователя",
      list: [
        "3.1 Список персональных данных, которые обрабатывает оператор: фамилия, имя, отчество, номер телефона, адрес электронной почты.",
        "3.2. Кроме того, на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика, Гугл Аналитика и других).",
      ],
    },
    {
      id: 4,
      title: "Цели обработки персональных данных",
      list: [
        "4.1 Персональные данные пользователя - фамилия, имя, отчество, номер телефона, адрес электронной почты - обрабатываются со следующей целью: Обсуждение деталей заказа клиента. Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес info@ntdev.ru",
        "4.2 Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.",
      ],
    },
    {
      id: 5,
      title: "Правовые основания обработки персональных данных",
      list: [
        "5.1 Оператор обрабатывает персональные данные Пользователя только в случае их отправки Пользователем через формы, расположенные на веб-сайте nt-dent.ru. Отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.",
        "5.2 Оператор обрабатывает обезличенные данные о Пользователе в случае, если Пользователь разрешил это в настройках браузера (включено сохранение файлов «cookie» и использование технологии JavaScript).",
      ],
    },
    {
      id: 6,
      title:
        "Порядок сбора, хранения, передачи и других видов обработки персональных данных",
      list: [
        "6.1 Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.",
        "6.2 Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.",
        "6.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их, направив Оператору уведомление с помощью электронной почты на электронный адрес Оператора info@ntdev.ru, либо на почтовый адрес Оператора: Россия, г. Москва, вн.тер.г. муниципальный округ Пресненский, наб. Пресненская, д. 12, с пометкой «Актуализация персональных данных».",
        "6.3 Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление с помощью электронной почты на электронный адрес Оператора info@ntdev.ru, либо на почтовый адрес Оператора: Россия, г. Москва, вн.тер.г. муниципальный округ Пресненский, наб. Пресненская, д. 12, с пометкой «Отзыв согласия на обработку персональных данных».",
      ],
    },
    {
      id: 7,
      title: "Заключительные положения",
      list: [
        "7.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты info@ntdev.ru, либо на почтовый адрес Оператора: Россия, г. Москва, вн.тер.г. муниципальный округ Пресненский, наб. Пресненская, д. 12.",
        "7.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. В случае существенных изменений Пользователю может быть выслана информация на указанный им электронный адрес.",
      ],
    },
  ];

  return ReactDOM.createPortal(
    <div onClick={onClose} className={styles.overlay}>
      <div className={styles.modal}>
        <div>
          <h2>Политика обработки персональных данных</h2>
          {policyData.map((item) => (
            <div key={item.id + item.title}>
              <h4>{item.id + "." + item.title}</h4>
              <ul>
                {item.list.map((listItem, ind) => (
                  <li key={ind}>{listItem}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>,
    modalRoot
  );
}
