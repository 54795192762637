import styles from "./RequestSentModalWindow.module.less";
import ReactDOM from "react-dom";
import { useEffect } from "react";

export interface RequestSentModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export function RequestSentModalWindow({
  isOpen,
  onClose,
}: RequestSentModalProps) {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  if (!isOpen) return null;
  const modalRoot = document.getElementById("portal") as HTMLElement;

  return ReactDOM.createPortal(
    <div onClick={onClose} className={styles.overlay}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div onClick={onClose} className={styles.close}></div>
        <h2 className={styles.heading}>Ваша заявка отправлена</h2>
        <p className={styles.text}>Мы свяжемся с вами в течение дня</p>
        <button onClick={onClose} className={styles.backBtn}>
          Вернуться на главную
        </button>
      </div>
    </div>,
    modalRoot
  );
}
