import styles from "./Header.module.less";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import React, { useState } from "react";
import { RequestSentModalWindow } from "../RequestSentModalWindow/RequestSentModalWindow";

export function Header() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [requestSentIsOpen, setRequestSentIsOpen] = useState<boolean>(false);

  return (
    <header className={styles.headerWrapper}>
      <div className={styles.container}>
        <div className={styles.logo}></div>
        <nav>
          <ul className={styles.menu}>
            <li className={styles.link}>
              <a href="#features">Возможности</a>
            </li>
            <li className={styles.link}>
              <a href="#plan">Тариф</a>
            </li>
            <li className={styles.link}>
              <a href="#trial">Пробный период</a>
            </li>
            <li>
              <button
                onClick={() => setIsOpen(true)}
                className={styles.trialBtn}
              >
                Начать бесплатно
              </button>
            </li>
            {/*<li>*/}
            {/*  <button className={styles.contactBtn}>*/}
            {/*    <svg*/}
            {/*      className={styles.whatsapp}*/}
            {/*      width="16"*/}
            {/*      height="16"*/}
            {/*      viewBox="0 0 16 16"*/}
            {/*      xmlns="http://www.w3.org/2000/svg"*/}
            {/*    >*/}
            {/*      <path d="M11.6714 9.53785L11.6654 9.58785C10.1994 8.8572 10.0461 8.75987 9.85676 9.04386C9.72543 9.24053 9.34276 9.68652 9.22743 9.81852C9.11076 9.94851 8.99476 9.95851 8.79676 9.86852C8.59676 9.76852 7.95476 9.55852 7.19476 8.87853C6.60276 8.34854 6.20543 7.69855 6.08809 7.49856C5.89276 7.16123 6.30143 7.11323 6.67343 6.40924C6.74009 6.26925 6.70609 6.15925 6.65676 6.05992C6.60676 5.95992 6.20876 4.97994 6.04209 4.58928C5.88209 4.19995 5.71743 4.24928 5.59409 4.24928C5.21009 4.21595 4.92943 4.22128 4.68209 4.47861C3.60609 5.66126 3.87743 6.88124 4.79809 8.17854C6.60743 10.5465 7.57143 10.9825 9.33409 11.5878C9.81009 11.7391 10.2441 11.7178 10.5874 11.6685C10.9701 11.6078 11.7654 11.1878 11.9314 10.7178C12.1014 10.2478 12.1014 9.85785 12.0514 9.76785C12.0021 9.67785 11.8714 9.62785 11.6714 9.53785Z" />*/}
            {/*      <path d="M13.68 2.29958C8.554 -2.65566 0.0706667 0.938271 0.0673333 7.92881C0.0673333 9.32612 0.433333 10.6888 1.13067 11.8921L0 16L4.22333 14.8987C9.49333 17.7453 15.9973 13.9654 16 7.93281C16 5.81552 15.1733 3.82289 13.67 2.32558L13.68 2.29958ZM14.668 7.91081C14.664 12.9994 9.078 16.1773 4.66 13.58L4.42 13.4374L1.92 14.0874L2.59 11.6574L2.43067 11.4074C-0.318667 7.03083 2.84 1.31093 8.048 1.31093C9.81733 1.31093 11.478 2.00092 12.7287 3.2509C13.9787 4.49021 14.668 6.15084 14.668 7.91081Z" />*/}
            {/*    </svg>*/}
            {/*    <span>Написать в WhatsApp</span>*/}
            {/*  </button>*/}
            {/*</li>*/}
          </ul>
        </nav>
        {isOpen && (
          <ModalWindow
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onSubmit={() => {
              setIsOpen(false);
              setRequestSentIsOpen(true);
            }}
          />
        )}
        {requestSentIsOpen && (
          <RequestSentModalWindow
            isOpen={requestSentIsOpen}
            onClose={() => setRequestSentIsOpen(false)}
          />
        )}
      </div>
    </header>
  );
}
