import styles from "./UseTerms.module.less";
import cx from "classnames";
import React, { useState } from "react";
import { ModalWindow } from "../ModalWindow/ModalWindow";
import { RequestSentModalWindow } from "../RequestSentModalWindow/RequestSentModalWindow";

export function UseTerms() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [requestSentIsOpen, setRequestSentIsOpen] = useState<boolean>(false);

  return (
    <section id="trial" className={styles.workingTerms}>
      <h2 className={styles.heading}>Начните работать в НТ Стоматологии</h2>
      <article className={cx(styles.card, styles.trialCard)}>
        <div
          className={cx(
            styles.cardHeading,
            styles.secondary,
            styles.freePeriod
          )}
        >
          Бесплатный пробный период — 2 месяца
        </div>
        <div>
          <p className={cx(styles.description, styles.secondary)}>
            Попробуйте все возможности нашей системы без ограничений. Свяжитесь
            с нами, чтобы получить доступ к НТ Стоматологии.
          </p>
          <button className={styles.trialBtn} onClick={() => setIsOpen(true)}>
            Подключить бесплатно
            <svg
              className={styles.arrow}
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_726_2682)">
                <path
                  d="M1.5 8.33325C7.26 8.33325 13 7.83325 17.5 3.33325"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10.9375 1.45674L17.4997 3.00998L17.4997 10"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_726_2682">
                  <rect
                    width="19"
                    height="14"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </article>
      {isOpen && (
        <ModalWindow
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSubmit={() => {
            setIsOpen(false);
            setRequestSentIsOpen(true);
          }}
        />
      )}
      {requestSentIsOpen && (
        <RequestSentModalWindow
          isOpen={requestSentIsOpen}
          onClose={() => setRequestSentIsOpen(false)}
        />
      )}
      <article id="plan" className={cx(styles.card, styles.termsCard)}>
        <div>
          <div className={styles.cardHeading}>Единый годовой тариф</div>
          <div className={styles.oldPrice}>
            <div className={styles.strikethrough}></div>
            120 000 ₽
          </div>
          <div className={styles.newPrice}>90 000 ₽</div>
          <div className={styles.sale}>
            <div className={styles.saleTag}>-25%</div>
            <div className={styles.saleText}>
              скидка на первое годовое использование
            </div>
          </div>
        </div>
        <div>
          <ul>
            <li className={styles.termItem}>
              <div className={styles.bulletIcon}></div>
              <div>
                <div>Расписание и запись пациентов</div>
                <div className={styles.subheading}>
                  Количество пациентов не ограничено
                </div>
              </div>
            </li>
            <li className={styles.termItem}>
              <div className={styles.bulletIcon}></div>
              <div>
                <div>Приемы и план лечения</div>
                <div className={styles.subheading}>
                  Включая готовые шаблоны и справочники
                </div>
              </div>
            </li>
            <li className={styles.termItem}>
              <div className={styles.bulletIcon}></div>
              <div>
                <div>Отчеты для руководителя</div>
                <div className={styles.subheading}>
                  Формируются автоматически за выбранный период
                </div>
              </div>
            </li>
            <li className={styles.termItem}>
              <div className={styles.bulletIcon}></div>
              <div>
                <div>Доступ для сотрудников</div>
                <div className={styles.subheading}>
                  Без ограничения по количеству компьютеров или кресел
                </div>
              </div>
            </li>
            <li className={styles.termItem}>
              <div className={styles.bulletIcon}></div>
              <div>
                <div>Техническая поддержка</div>
                <div className={styles.subheading}>
                  В рабочие дни с 10 до 19 по МСК
                </div>
              </div>
            </li>
          </ul>
          <button onClick={() => setIsOpen(true)} className={styles.applyBtn}>
            Отправить заявку
            <svg
              className={styles.arrow2}
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_726_2682)">
                <path
                  d="M1.5 8.33325C7.26 8.33325 13 7.83325 17.5 3.33325"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10.9375 1.45674L17.4997 3.00998L17.4997 10"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_726_2682">
                  <rect
                    width="19"
                    height="14"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </article>
      <article className={cx(styles.card, styles.transferCard)}>
        <div>
          <div className={styles.cardHeading}>Перенос данных</div>
          <div className={styles.price}>18 000 ₽</div>
        </div>
        <p className={styles.description}>
          Наши специалисты помогут автоматизировать перенос клиентской базы,
          прайс-листа и других данных вашей клиники в НТ Стоматологию. Услуга не
          входит в основной тариф, выполняется один раз.
        </p>
      </article>
    </section>
  );
}
