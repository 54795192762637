import React, { useState } from "react";

interface ProgressiveImageProps {
  blurImageUrl: string;
  mainImageUrl: string;
  currentRef?: React.Ref<any>;
  className?: string;
  altAttr?: string;
}
export const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  blurImageUrl,
  mainImageUrl,
  currentRef,
  className,
  altAttr,
}) => {
  let [imageLoadingState, setImageLoadingState] = useState(false);

  return (
    <img
      src={imageLoadingState ? mainImageUrl : blurImageUrl}
      alt={altAttr}
      className={className}
      onLoad={() => setImageLoadingState(true)}
      ref={currentRef}
    />
  );
};
