import ReactDOM from "react-dom";
import styles from "./ModalWindow.module.less";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import cx from "classnames";
import axios from "axios";
import config from "../../config.json";

export interface ModalProps {
  isOpen: boolean;
  withMessageField?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

type ValidationErrors = {
  name: string | null;
  phone: string | null;
  message: string | null;
};

type SendingStatus = "pending" | "success" | "error" | null;

export function ModalWindow({
  isOpen,
  onClose,
  onSubmit,
  withMessageField,
}: ModalProps) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<ValidationErrors>({
    name: null,
    phone: null,
    message: null,
  });
  const [limitSubmit, setLimitSubmit] = useState(false);
  const [sendingStatus, setSendingStatus] = useState<SendingStatus>(null);
  axios.defaults.baseURL = config.apiPath;

  const validateName = (name: string) => {
    if (!name.trim()) {
      return {
        name: "Заполните поле",
      };
    }
    //Если ошибок валидации нет
    return {
      name: null,
    };
  };
  const validateMessage = (message: string) => {
    if (withMessageField) {
      if (!message.trim()) {
        return {
          message: "Заполните поле",
        };
      }
      //Если ошибок валидации нет
      return {
        message: null,
      };
    }
    return;
  };

  const validatePhone = (phone: string) => {
    if (!phone) {
      return {
        phone: "Заполните поле",
      };
    }

    if (!/\+7 \(\d{3}\) \d{3} \d{2} \d{2}/.test(phone)) {
      return {
        phone: "Неверный формат",
      };
    }

    return {
      phone: null,
    };
  };

  const resetErrorOnChange = (fieldName: keyof ValidationErrors) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: null,
    }));
  };

  const checkInputChange = (oldValue: string, newValue: string) => {
    if (newValue !== oldValue) {
      setLimitSubmit(false);
    }
  };

  const submitWithValidation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const restrictSameInfoSubmission = (
      name: string,
      phone: string,
      message: string
    ) => {
      const formInfoToCheck = withMessageField
        ? `${name}${phone}${message}`
        : `${name}${phone}`;
      const formInfo = localStorage.getItem("formInfo");
      const isSameInfo = formInfo === formInfoToCheck;
      if (isSameInfo) {
        setLimitSubmit(true);
        return true;
      }
      localStorage.setItem("formInfo", formInfoToCheck);
      setTimeout(() => {
        localStorage.setItem("formInfo", "");
        setLimitSubmit(false);
      }, 60000);
      return false;
    };

    const validationErrors = {
      ...validateName(name),
      ...validatePhone(phone),
      ...validateMessage(message),
    };

    if (Object.values(validationErrors).every((item) => item === null)) {
      //Если нет ошибок валидации
      const isRestrictSubmit = restrictSameInfoSubmission(name, phone, message);
      !isRestrictSubmit && sendForm({ name, phone, message });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...validationErrors,
      }));

      return;
    }
  };

  const sendForm = (data: any) => {
    const currentUrl = withMessageField ? "/feedback/" : "/feedback/call";
    setSendingStatus("pending");
    axios
      .post(currentUrl, data)
      .then(() => {
        onSubmit();
        setSendingStatus("success");
      })
      .catch((err) => {
        setSendingStatus("error");
      });
  };

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onClose]);

  if (!isOpen) {
    setSendingStatus(null);
    return null;
  }
  const modalRoot = document.getElementById("portal") as HTMLElement;

  const modalHeader = withMessageField
    ? "Отправить предложение"
    : "Подключите НТ Стоматологию";

  return ReactDOM.createPortal(
    <div onClick={onClose} className={styles.overlay}>
      <form
        className={styles.modal}
        onClick={(e) => e.stopPropagation()}
        onSubmit={(e) => submitWithValidation(e)}
      >
        <button autoFocus={true} onClick={onClose} className={styles.close}>
          <svg
            width="42"
            height="24"
            viewBox="0 0 42 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.1259 0.0523924C18.198 -0.197674 19.2724 0.471465 19.5256 1.54696C19.7788 2.62245 19.115 3.69702 18.0428 3.94709L5.48956 6.87505C15.2265 15.0519 27.287 16.0976 40.006 16.0976C41.1073 16.0976 42 16.9931 42 18.0976C42 19.2022 41.1073 20.0976 40.006 20.0976C27.6245 20.0976 14.7027 19.1716 3.98933 10.8009V22.0067C3.98933 23.1108 3.09629 24.0028 1.99467 23.999C0.893044 23.9952 3.8147e-06 23.097 3.8147e-06 21.9928V4.04688L17.1259 0.0523924Z"
              fill="url(#paint0_linear_756_780)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_756_780"
                x1="21"
                y1="0"
                x2="21"
                y2="23.999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#0082AF" />
                <stop offset="1" stopColor="#00C0D9" />
              </linearGradient>
            </defs>
          </svg>
        </button>
        <h2 className={styles.heading}>{modalHeader}</h2>
        {!withMessageField && (
          <p className={styles.text}>
            Оставьте заявку, и мы свяжемся с вами для организации пробного
            периода
          </p>
        )}
        <p className={styles.label}>Как к вам обращаться?</p>
        <input
          placeholder={"Представьтесь, пожалуйста"}
          className={cx(styles.input, errors.name ? styles.inputError : "")}
          value={name}
          onChange={(e) => {
            checkInputChange(e.target.value, name);
            setName(e.target.value);
            resetErrorOnChange("name");
          }}
        />
        {errors.name && (
          <span className={styles.errorMessage}>{errors.name}</span>
        )}
        <p className={styles.label}>Телефон для связи</p>
        <InputMask
          mask="+7 (999) 999 99 99"
          placeholder={"+7 (000) 000 00 00"}
          className={cx(styles.input, errors.phone ? styles.inputError : "")}
          value={phone}
          onChange={(e) => {
            checkInputChange(e.target.value, phone);
            setPhone(e.target.value);
            resetErrorOnChange("phone");
          }}
        />
        {errors.phone && (
          <span className={styles.errorMessage}>{errors.phone}</span>
        )}
        {withMessageField && (
          <>
            <p className={styles.label}>Текст сообщения:</p>
            <input
              placeholder={"Текст сообщения"}
              className={cx(
                styles.input,
                errors.message ? styles.inputError : ""
              )}
              value={message}
              onChange={(e) => {
                checkInputChange(e.target.value, message);
                setMessage(e.target.value);
                resetErrorOnChange("message");
              }}
            />
          </>
        )}
        {errors.message && (
          <span className={styles.errorMessage}>{errors.message}</span>
        )}
        <div className={styles.modalFlexContainer}>
          <button
            disabled={limitSubmit}
            type="submit"
            className={styles.sendBtn}
          >
            Отправить заявку
          </button>
          {limitSubmit && (
            <p className={styles.errorText}>
              Вы уже отправляли заявку. Пожалуйста, подождите.
            </p>
          )}
          {sendingStatus && (
            <p className={styles.errorText}>
              {sendingStatus === "pending" && "Отправка..."}
              {sendingStatus === "error" &&
                "Что-то пошло не так, попробуйте еще раз"}
            </p>
          )}
        </div>
      </form>
    </div>,
    modalRoot
  );
}
